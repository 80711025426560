import React, { Component } from 'react'

import api from '../../utils/api';

import UserInputField from './UserInputField'
import OutputArea from './OutputArea'

const emptyAPIResponse = {
  code: 0,
  status: '',
  data: {
    message: 'Enter incident details...',
    name: '',
    severity: '',
    timeline: ''
  }
}

class UserInputSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userInput: '',
      apiResponse: emptyAPIResponse
    };

    this.handleTextChange = this.handleTextChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.clearInputText = this.clearInputText.bind(this);
  }

  handleTextChange = (event, stateObjectKey) => {
    event.preventDefault();
    const newState = event.target.value;
    this.setState({ [stateObjectKey]: newState });
  };

  clearInputText = () => {
    this.setState(() => ({ userInput: '', apiResponse: emptyAPIResponse }));
  };

  handleSubmit = async () => {
    this.setState({ apiResponse: {
      data: {
        message: 'Loading...',
      }
    }});
    const { userInput } = this.state;
    const payload = { userInput };
    try{
      let res = await api.processAndSubmitToNotion(payload);
      this.setState({ apiResponse: res, userInput: '' });
    } catch (error) {
      this.setState({ apiResponse: error, userInput: ''});
    }
  };

  handleProcessSources = async () => {
    this.setState({ apiResponse: {
      data: {
        message: 'Processing sources...',
      }
    }});
    try {
      let res = await api.processSources({});
      this.setState({ apiResponse: res });
    } catch (error) {
      this.setState({ apiResponse: error });
    }
  };

  render() {
    const { userInput, apiResponse } = this.state;
    
    return (
      <section className="row">
        <div className="col-md-12">
          <h5>Incident Details</h5>
          <UserInputField 
            type="textarea" 
            value={userInput} 
            handleChange={(e) => { this.handleTextChange(e, 'userInput') }} 
          />
          <button type="button" className="btn btn-primary my-3" onClick={this.handleSubmit}>Process Incident</button>
          <button type="button" className="btn btn-outline-secondary mx-3" onClick={this.clearInputText}>Clear</button>
          <button type="button" className="btn btn-info mx-3" onClick={this.handleProcessSources}>Process Notion Sources</button>
          <h5>Output</h5>
          <OutputArea response={apiResponse}/>
        </div>
      </section>
    )
  }
}

export default UserInputSection;



//  A Task is an actionable item or specific activity that a person intends to complete within a month timeframe, often with a specific objective or outcome in mind.Tasks can be assigned priorities, have deadlines, and may be part of a larger project or goal.
//     A Thought is an idea, reflection, or mental impression that arises spontaneously or as a result of contemplation or external stimuli.Thoughts can be abstract, subjective, or related to personal experiences, and may not necessarily require immediate action or have a specific goal attached.Thoughts can be ideas for future or current projects, goals, or tasks, or they can be reflections on past experiences or events.