import React from 'react'

export default function OutputArea(props: any) {
  const { response } = props;
  const { data = {} } = response;
  const { message = '', incident = {}, indicatorsCount = 0, blocklistCount = 0, processed = 0, successful = 0, failed = 0 } = data;
  
  let messageMarkup;

  if (response.code === 500 || response.status === "error") {
    messageMarkup = (
      <div className="alert alert-danger" role="alert">
        {message}
      </div>
    );
  } else if (response.code === 201 || (response.status === "success" && incident.name)) {
    messageMarkup = (
      <div className="alert alert-success" role="alert">
        <p>{message}</p>
        <ul>
          <li>Incident Name: {incident.name}</li>
          <li>Severity: {incident.severity}</li>
          <li>Indicators Added: {indicatorsCount}</li>
          <li>Blocklist Entries: {blocklistCount}</li>
        </ul>
      </div>
    );
  } else if (response.status === "success" && processed !== undefined) {
    messageMarkup = (
      <div className="alert alert-success" role="alert">
        <p>{message}</p>
        <ul>
          <li>Total Sources Processed: {processed}</li>
          <li>Successfully Processed: {successful}</li>
          <li>Failed: {failed}</li>
        </ul>
      </div>
    );
  } else {
    messageMarkup = (
      <div className="alert alert-light" role="alert">
        <p>{message}</p>
      </div>
    );
  }

  return messageMarkup;
}
