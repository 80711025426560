import fetch from 'node-fetch';

const API_URL = process.env.REACT_APP_API_DOMAIN || 'http://localhost:8000';

async function processAndSubmitToNotion(payload) {
  console.log('Sending request to:', `${API_URL}/api/model/process`);
  console.log('Request payload:', payload);
  
  try {
    const response = await fetch(`${API_URL}/api/model/process`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload)
    });
    
    const data = await response.json();
    console.log('Response received:', data);
    return data;
  } catch (error) {
    console.error('API Error:', error);
    throw error;
  }
}

async function processSources(payload) {
  console.log('Sending request to:', `${API_URL}/api/model/process-sources`);
  console.log('Request payload:', payload);
  
  try {
    const response = await fetch(`${API_URL}/api/model/process-sources`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload)
    });
    
    const data = await response.json();
    console.log('Response received:', data);
    return data;
  } catch (error) {
    console.error('API Error:', error);
    throw error;
  }
}

export default {
  processAndSubmitToNotion,
  processSources
};
